import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import cn from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { Pagination, FreeMode } from 'swiper'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'
import { Button } from '../../components/Common/Button'
import ArrowButton from '../../components/Common/ArrowButton'
import ModalIframe from '../../components/Common/ModalIframe'

import * as s from '../../pages/news.module.scss'

import './JulyDigest2024.styles.scss'
import ModalImage from '../../components/Common/ModalImage'
import YoutubePlayIcon from '../../svg/youtube-play-icon'

const JulyDigest2024 = () => {
  const images = useStaticQuery(imagesQuery)
  const [imageData, setImageData] = React.useState<{
    title: string
    image: string
  }>()
  const [modalVideo, setModalVideo] = React.useState(false)
  return (
    <>
      <SEO
        title="Digest of the July updates on SIGNAX products"
        description="SIGNAX July 2024 Digest of updates and product materials, including SIGNAX DOCS CDE, INSPECTION - remote construction control service, DASHBOARD project management software and TOOLS solution for BIM management automation."
      />
      <NewsSectionBlock date="August 13, 2024">
        <Typography variant="h1" color="blue">
          July Digest SIGNAX
        </Typography>
        <Typography variant="h2" size={24}>
          Product Updates
        </Typography>
        <Typography variant="body1">
          Exciting news! We’ve rolled out some fantastic updates for our SIGNAX
          software this July. This update comes packed with significant
          enhancements, including the DOCS module's enhanced document management
          with new features. We have also released new product videos featuring
          SIGNAX modules and specific functions to enhance project efficiency.
          Additionally, we published an article in collaboration with one of our
          customer's lead engineers about Checker, a solution for BIM management
          automation, and an article about use cases of SIGNAX INSPECTION.
        </Typography>
        <SignaxDocsBlock />
        <Typography variant="h3" size={18}>
          SIGNAX DASHBOARD
        </Typography>
        <Typography variant="body1">
          In July we uploaded our first overview of the Dashboard module to
          introduce its advantages to our potential clients. And we are happy to
          remind you about our project management solution, delivering the
          latest project updates straight to your mobile device or desktop.
        </Typography>
        <div className={s.youtubeVideo} onClick={() => setModalVideo(true)}>
          <GatsbyImage
            image={images.videoPreview1.childImageSharp.gatsbyImageData}
            alt="SIGNAX DASHBOARD. How it works"
          />
          <YoutubePlayIcon />
        </div>

        <div className={cn(s.contentWrap, s.margin_top_48)}>
          <div className={s.textWrapCentered}>
            <Typography variant="h3" size={18} mt={16}>
              SIGNAX TOOLS
            </Typography>
            <Typography variant="body1" size={18}>
              We're thrilled to share a new article on the SIGNAX TOOLS Checker
              feature, implemented by Pavel Goretskiy and Mahmoud El-Khatib. The
              article highlights the successful integration of SIGNAX TOOLS in a
              Basalt Fiber factory project, showcasing its role in BIM
              coordination and quality assurance.
            </Typography>
            <Button
              size="small"
              onClick={() => navigate('/bim-management-automation/')}
            >
              Open Article
            </Button>
          </div>

          <div
            className="july-digest_image july-digest_image_bottom"
            onClick={() =>
              setImageData({ title: 'SIGNAX TOOLS Article', image: 'image6' })
            }
          >
            <GatsbyImage
              image={images.image6Preview.childImageSharp.gatsbyImageData}
              alt="SIGNAX TOOLS Article"
            />
          </div>
        </div>

        <div className={cn(s.contentWrap, s.margin_top_48)}>
          <div className={s.textWrapCentered}>
            <Typography variant="h3" size={18} mt={16}>
              SIGNAX INSPECTION
            </Typography>
            <Typography variant="body1" size={18}>
              Our latest article focuses on SIGNAX INSPECTION and outlines 6
              Reasons to Digitize Your Construction QA/QC. In this article, we
              describe use c ases of the INSPECTION module, its advantages, and
              its impact on the QA/QC process.
            </Typography>
            <Button
              size="small"
              onClick={() => navigate('/6-reasons-to-use-inspection/')}
            >
              Open Article
            </Button>
          </div>

          <div
            className="july-digest_image july-digest_image_bottom"
            onClick={() =>
              setImageData({
                title: 'SIGNAX INSPECTION Article',
                image: 'image7',
              })
            }
          >
            <GatsbyImage
              image={images.image7Preview.childImageSharp.gatsbyImageData}
              alt="SIGNAX INSPECTION Article"
            />
          </div>
        </div>
      </NewsSectionBlock>
      {modalVideo && (
        <ModalIframe
          title="SIGNAX DASHBOARD. How it works"
          src="https://youtube.com/embed/hwgjGLTO3l0?si=C4s7_sHkfOJwyIA"
          srcTitle="SIGNAX DASHBOARD. How it works"
          onClose={() => setModalVideo(false)}
        />
      )}
      {imageData && (
        <ModalImage
          title={imageData.title}
          onClose={() => setImageData(undefined)}
        >
          <GatsbyImage
            image={images[imageData.image].childImageSharp.gatsbyImageData}
            alt={imageData.title}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: '50% 50%',
              maxHeight: ' calc(100vh - 100px)',
            }}
          />
        </ModalImage>
      )}
    </>
  )
}

const SignaxDocsBlock = () => {
  const [modalData, setModalData] = React.useState<{
    title: string
    imageId: string
  }>()
  const images = useStaticQuery(imagesQuery)
  const docsData = [
    {
      title: 'PDF comparison',
      text: ' Compare two versions of a PDF file or two separate PDF files. Align and scale the files and adjust detailing and matching threshold settings for optimal results.',
      imagePreviewId: 'image1Preview',
      imageId: 'image1',
    },
    {
      title: 'Restarting Reviews',
      text: 'Create a new review based on an old one while updating the old set files to the latest version.',
      imagePreviewId: 'image2Preview',
      imageId: 'image2',
    },
    {
      title: 'Calendar in Reviews Registry',
      text: 'A calendar has been added to the Reviews registry to visually represent the stages (phases) of the approval processes.',
      imagePreviewId: 'image3Preview',
      imageId: 'image3',
    },
    {
      title: 'Edit Reviews',
      text: 'Project Administrators can now edit active Reviews.',
      imagePreviewId: 'image4Preview',
      imageId: 'image4',
    },
    {
      title: 'Comments and Event Log',
      text: 'Comments and an Event Log have been added to Issues.',
      imagePreviewId: 'image5Preview',
      imageId: 'image5',
    },
  ]

  return (
    <>
      <Typography variant="h3" size={18}>
        SIGNAX DOCS
      </Typography>
      <Typography variant="body1">
        We are actively developing SIGNAX DOCS Common Data Environment as per
        our plan. In July update, we have implemented several improvements and
        new features:
      </Typography>
      {docsData.map((item, idx) => (
        <>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrapCentered}>
              <Typography variant="h3" size={18} mb={16} mt={16}>
                {item.title}
              </Typography>
              <Typography variant="body1" size={18}>
                {item.text}
              </Typography>
            </div>
            <div
              className="july-digest_image"
              onClick={() => setModalData(docsData[idx])}
            >
              <GatsbyImage
                image={
                  images[item.imagePreviewId].childImageSharp.gatsbyImageData
                }
                alt={item.title}
              />
            </div>
          </div>
        </>
      ))}
      {modalData && (
        <ModalImage
          title={modalData.title}
          onClose={() => setModalData(undefined)}
        >
          <GatsbyImage
            image={images[modalData.imageId].childImageSharp.gatsbyImageData}
            alt={modalData.title}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: '50% 50%',
              maxHeight: ' calc(100vh - 100px)',
            }}
          />
        </ModalImage>
      )}
      <Typography variant="body1">
        In July, we also uploaded videos about the viewer and collaboration in
        DOCS, company account management, and management optimization using the
        Point Cloud feature:
      </Typography>
      <CarouselProductVideosBlock />
    </>
  )
}

const CarouselProductVideosBlock = () => {
  const [youtubeData, setYoutubeData] = React.useState<{
    src: string
    title: string
    description: string
    imageId: string
  }>()
  const swiperRef = React.useRef<SwiperRef>(null)
  const [activeSlideIdx, setActiveSlideIdx] = React.useState(0)

  const [disabledNavigationPrev, setDisabledNavigationPrev] =
    React.useState(true)
  const [disabledNavigationNext, setDisabledNavigationNext] =
    React.useState(false)

  const images = useStaticQuery(imagesQuery)

  const youtubeVideo = [
    {
      src: 'https://youtube.com/embed/FZtdHHd4B-4?si=wJB6eU5VWoFKYtN2',
      title: 'SIGNAX DOCS. Documents viewer and collaboration',
      description: '',
      imageId: 'shortsPreview1',
    },
    {
      src: 'https://youtube.com/embed/xZsi1fkyXYs?si=6rhnoHwjEtpWKRcV',
      title: 'SIGNAX HUB. Account registration',
      description: '',
      imageId: 'shortsPreview2',
    },
    {
      src: 'https://youtube.com/embed/hwgjGLTO3l0?si=eny-6L1qchK5NkkV',
      title: 'SIGNAX POINT CLOUD. Construction Material Storage',
      description: '',
      imageId: 'shortsPreview3',
    },
  ]

  return (
    <>
      <div className="flex items-center">
        <ArrowButton
          onClick={() => swiperRef.current?.swiper.slidePrev()}
          disabled={disabledNavigationPrev}
          arrowType="left"
          className="july-digest_btn"
        />
        <Swiper
          ref={swiperRef}
          freeMode={true}
          grabCursor={true}
          navigation={true}
          onSlideChange={swiper => {
            setDisabledNavigationPrev(swiper.isBeginning)
            setDisabledNavigationNext(swiper.isEnd)
            setActiveSlideIdx(swiper.activeIndex)
          }}
          centeredSlides
          initialSlide={1}
          wrapperClass="mb-6"
          pagination={{
            clickable: true,
            bulletClass: 'july-digest_pagination',
            bulletActiveClass: 'active',
          }}
          slidesPerView="auto"
          modules={[FreeMode, Pagination]}
        >
          {youtubeVideo.map((item, idx) => {
            const isActiveSlide = activeSlideIdx === idx
            return (
              <SwiperSlide key={idx} className="july-digest_slide">
                <div
                  style={{
                    opacity: !isActiveSlide ? 0.6 : undefined,
                  }}
                  className="relative july-digest_poster"
                  onClick={() => setYoutubeData(item)}
                >
                  <GatsbyImage
                    image={images[item.imageId].childImageSharp.gatsbyImageData}
                    className="w-100 h-100"
                    alt={item.title}
                  />
                  <YoutubePlayIcon
                    width={76}
                    height={76}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      zIndex: 1,
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
                <div style={{ opacity: isActiveSlide ? 1 : 0 }}>
                  <Typography
                    variant="body1"
                    color="blue"
                    mt={24}
                    className="has-text-weight-medium mb-2"
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body1" className="m-0">
                    {item.description}
                  </Typography>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <ArrowButton
          arrowType="right"
          onClick={() => swiperRef.current?.swiper.slideNext()}
          disabled={disabledNavigationNext}
          className="july-digest_btn"
        />
      </div>
      {youtubeData && (
        <ModalIframe
          title={youtubeData.title}
          src={youtubeData.src}
          srcTitle={youtubeData.title}
          onClose={() => setYoutubeData(undefined)}
        />
      )}
    </>
  )
}

const imagesQuery = graphql`
  query {
    image1Preview: file(
      relativePath: { eq: "news/july-digest-2024/image-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 564, placeholder: BLURRED)
      }
    }
    image1: file(relativePath: { eq: "news/july-digest-2024/image-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image2Preview: file(
      relativePath: { eq: "news/july-digest-2024/image-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 564, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "news/july-digest-2024/image-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image3Preview: file(
      relativePath: { eq: "news/july-digest-2024/image-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 564, placeholder: BLURRED)
      }
    }
    image3: file(relativePath: { eq: "news/july-digest-2024/image-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image4Preview: file(
      relativePath: { eq: "news/july-digest-2024/image-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 564, placeholder: BLURRED)
      }
    }
    image4: file(relativePath: { eq: "news/july-digest-2024/image-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image5Preview: file(
      relativePath: { eq: "news/july-digest-2024/image-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 564, placeholder: BLURRED)
      }
    }
    image5: file(relativePath: { eq: "news/july-digest-2024/image-5.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image6Preview: file(
      relativePath: { eq: "news/july-digest-2024/image-6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 564, placeholder: BLURRED)
      }
    }
    image6: file(relativePath: { eq: "news/july-digest-2024/image-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    image7Preview: file(
      relativePath: { eq: "news/july-digest-2024/image-7.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 564, placeholder: BLURRED)
      }
    }
    image7: file(relativePath: { eq: "news/july-digest-2024/image-7.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    shortsPreview1: file(
      relativePath: { eq: "news/july-digest-2024/shorts-preview-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 297, placeholder: BLURRED)
      }
    }
    shortsPreview2: file(
      relativePath: { eq: "news/july-digest-2024/shorts-preview-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 297, placeholder: BLURRED)
      }
    }
    shortsPreview3: file(
      relativePath: { eq: "news/july-digest-2024/shorts-preview-3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 297, placeholder: BLURRED)
      }
    }
    videoPreview1: file(
      relativePath: { eq: "news/july-digest-2024/video-preview-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
  }
`

export default JulyDigest2024
