import React from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import CloseIcon from '../../../svg/close-icon'

import * as s from './modal.module.scss'

interface ModalProps {
  onClose: () => void
  title?: string
  className?: string
  contentClassName?: string
  closeIconClassName?: string
}

const Modal: React.FC<ModalProps> = ({
  title,
  onClose,
  className,
  contentClassName,
  closeIconClassName,
  children,
}) => {
  React.useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
    return () => {
      document.getElementsByTagName('html')[0].style.overflowY = 'scroll'
    }
  }, [])

  return ReactDOM.createPortal(
    <div className={cn(s.modal, className)}>
      <div className={s.overlay} onClick={onClose} />
      <div className={cn(s.modal_content, contentClassName)}>
        <div className={s.modal_top}>
          <span className={s.title}></span>
          <CloseIcon
            onClick={onClose}
            className={cn(s.close_icon, closeIconClassName)}
          />
        </div>
        <div className="w-100 h-100">{children}</div>
      </div>
    </div>,
    document.body
  )
}

export default Modal
